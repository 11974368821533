import React, { useState, useEffect } from "react";
import cube from "../../../images/cube.png";
import homeAccordian from "../../../images/home-accordian.png";
import analysis from "../../../images/analysis.png";
import babyModalReplacement from "../../../images/abc.png";
import addressDeatils from "../../../images/address-detail.png";
import addressDocuments from "../../../images/address-document.png";
import { exportBuildingReport } from "./api/downloadReports";
import { useAccess } from "../context/accessControl";
import { AddressList } from "./addressList";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import CopyToClipboardButton from "./pathComponents/tooltip/CopyToClipboardButton";
export const BuildingDetailsTable = ({
  item,
  buildingId,
  addressReportList,
  rowHeadData,
  buildingReportList,
  drivePath,
}) => {
  const { suprmenAdmin } = useAccess();
  const { t } = useTranslation();
  const handleButtonClick = (reportId, fileName, fileId) => {
    if (fileId) {
      exportBuildingReport(reportId, fileName);
    } else {
      toast.error(`${t("fileDownload_errorMessage")}`);
    }
  };
  return (
    <tr>
      <td colSpan="8">
        <div className="dropdown-content">
          <div className="building-details-block">
            <div className="building-details-heading">
              <img
                className="homeaccordian"
                src={homeAccordian}
                alt="homeAccordian"
              />
              <h2>Building Details</h2>
            </div>
            <div className="building-details-data">
              <div className="building-details-img">
                <img
                  src={
                    item.babyModel
                      ? `data:image/png;base64,${item.babyModel}`
                      : babyModalReplacement
                  }
                  className="babyModalImg"
                  alt="building"
                />
              </div>
              <div className="building-details-sub-data">
                <h2>{item.name}</h2>
                <p>{item.streetName}</p>
                <p>{item.postcode}</p>
                <h2>Pand Id: {item.pandId}</h2>
              </div>
              <div className="building-details-actions">
                <button class="analysis" type="submit">
                  <img src={analysis} />
                  <a>Analysis</a>
                </button>
                <div className="informationButtons">
                  {buildingReportList.map((reportItem, index) => (
                    <React.Fragment key={index}>
                      <div className="buttonRow">
                        <button
                          className={`${
                            reportItem.reportId
                              ? "actionsBtn3 active"
                              : "mjopData"
                          }`}
                          type="button"
                          onClick={() =>
                            handleButtonClick(
                              reportItem.reportId,
                              reportItem.fileName,
                              reportItem.fileId
                            )
                          }
                          disabled={!reportItem.reportId}
                        >
                          <img
                            src={reportItem.reportType === 'Export' ? analysis : cube}
                            alt="NEN2767"
                          />
                          <a>{reportItem.reportType}</a>
                        </button>
                        {drivePath && suprmenAdmin && (
                          <CopyToClipboardButton link={drivePath} />
                        )}
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="address-documents">
            <div className="address-heading-block">
              <div className="address-details">
                <img
                  className="addressDeatils"
                  src={addressDeatils}
                  alt="addressDeatils"
                />
                <h2>Address details</h2>
              </div>
            </div>
            <div className="address-table-content">
              <AddressList
                buildingId={buildingId}
                addressReportList={addressReportList}
                rowHeadData={rowHeadData}
              />
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};
