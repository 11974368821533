import React, { useState } from "react";
import "../projectList.modules.css";
import pdfIcon from "../../../images/pdfFileIcon.png";
import dullPdf from "../../../images/dullPdf.png";
import viewReport from "../../../images/view-report.png";
import { exportAddressReport } from "./api/downloadReports";
import { useAccess } from "../context/accessControl";
import lockedFile from "../../../images/LockedFile.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import CopyToClipboardButton from "./pathComponents/tooltip/CopyToClipboardButton";

export const AddressList = ({ rowHeadData, addressReportList, buildingId }) => {
  const handlePageRedirection = () => {
    window.location.href = `/addresslistPage/${buildingId}`;
  };
  const { t } = useTranslation();
  const { suprmenAdmin } = useAccess();
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const errorMessageFunction = () => {
    toast.error(`${t("fileDownload_errorMessage")}`);
  };

  const getColumnContent = (reportList, columnTitle) => {
    const report = reportList?.find((r) => r.reportType === columnTitle);
    const className =
      columnTitle === "NEN2767"
        ? "second"
        : columnTitle === "NEN2580"
        ? "third"
        : columnTitle === "WWS"
        ? "fourth"
        : columnTitle === "EPW"
        ? "fifth"
        : columnTitle === "Floor Plan"
        ? "sixth"
        : "default";
    if (report) {
      return (
        <td key={report.reportId} className={className}>
          {report.fileId ? (
            <img
              src={pdfIcon}
              onClick={() =>
                exportAddressReport(report.reportId, report.fileName)
              }
              alt="pdf"
              className="pointer"
            ></img>
          ) : (
            <>
              <img src={pdfIcon} alt="pdf" className="floor-overlay"/>
              <img
                src={lockedFile}
                onClick={errorMessageFunction}
                alt="blockedPdf"
                className="overlay-image"
              />
            </>
          )}
        </td>
      );
    } else {
      return (
        <td key={columnTitle} className={className}>
          <img src={dullPdf} alt="dull pdf"></img>
        </td>
      );
    }
  };

  return (
    <div className="addressListTableDiv">
      <table>
        <thead>
          <tr className="addressListTableDivTr">
            <th className="first"></th>
            {rowHeadData?.map((item) => (
              <th
                key={item.title}
                className={
                  item.title === "NEN2767"
                    ? "second"
                    : item.title === "NEN2580"
                    ? "third"
                    : item.title === "WWS"
                    ? "fourth"
                    : item.title === "EPW"
                    ? "fifth"
                    : item.title === "Floor Plan"
                    ? "sixth"
                    : "default"
                }
              >
                {" "}
                {item.title}{" "}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {addressReportList?.map((address, index) => (
            <tr key={index} className="addressListTableDivTr">
              <td
                className="first"
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                {address.streetName} {address.houseNo}
                {suprmenAdmin && hoveredIndex === index && (
                 <CopyToClipboardButton link={address.drivePath} />
                )}
              </td>
              {rowHeadData.map((column) =>
                getColumnContent(address.reportList, column.title)
              )}
            </tr>
          ))}
        </tbody>
      </table>

      <div className="address-report">
        <img
          className="view-report"
          src={viewReport}
          onClick={handlePageRedirection}
          alt="viewReport"
        />
      </div>
    </div>
  );
};
