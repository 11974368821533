import React, { useState, useEffect } from "react";
import downArrow from "../../../images/down.png";
import babyModalReplacement from "../../../images/abc.png";
import { callReportType } from "./api/reportType";
import { useTranslation } from "react-i18next";
import { projectBuildingData } from "./api/projectBuidlingData";
import { BuildingDetailsTable } from "./secondDropDown";
import cube from "../../../images/cube.png";
import api from "../../../helper/auth_interceptor";
import { getUser } from "../../../helper/auth_helper";

export const DropdownContent = ({ data }) => {
  const [openRowIds, setOpenRowIds] = useState([]);
  const [buildingData, setBuildingData] = useState({}); // Store data per building ID
  const [rowHeadData, setRowHeadData] = useState([]);
  const [omnibaseUrl, setOmnibaseUrl] = useState("");
  const { t } = useTranslation();
  const getVisualizationLink = async () => {
    const user = await getUser();
    const getApi = `${process.env.REACT_APP_API_GATEWAY_URL}/visualization-management/api/get-omnibase-link`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    try {
      const response = await api.get(getApi, { headers });
      setOmnibaseUrl(response.data);
    } catch (error) {
      console.log("Error:", error);
    }
  };
  useEffect(() => {
    getVisualizationLink();
  }, []);

  const callprojectBuildingData = async (id) => {
    if (!buildingData[id]) {
      const buildingDataResponse = await projectBuildingData(id);
      setBuildingData((prevData) => ({
        ...prevData,
        [id]: {
          addressReportList: buildingDataResponse.data.addressReportResponseList,
          buildingReportList: buildingDataResponse.data.buildingReports,
        },
        drivePath: buildingDataResponse.data.drivePath
      }));
      console.log(buildingDataResponse.data);
    }
  };

  const ReportTypes = async () => {
    if (rowHeadData.length === 0) {
      const reportData = await callReportType();
      setRowHeadData(reportData.data);
    }
  };

  const handleToggle = (id) => {
    setOpenRowIds((prevIds) => {
      if (!prevIds.includes(id)) {
        // The row is being opened, so fetch the project building data if not already fetched
        callprojectBuildingData(id);
        ReportTypes();
        return [...prevIds, id];
      } else {
        // The row is being closed
        return prevIds.filter((openId) => openId !== id);
      }
    });
  };

  return (
    <div className="list-sub-accordian-row">
      {data ? (
        <table>
          <tbody>
            {data.map((item) => (
              <React.Fragment>
                <tr className="list-sub-accordian" key={item.id}>
                  <td colSpan="9">
                    <div className="row-wrapper">
                      <table className="inner-table">
                        <tbody>
                          <tr>
                            <td  style={{ width: "5%" }}>
                              <button
                                className="dropdownBtn"
                                onClick={() => handleToggle(item.id)}
                              >
                                <img
                                  className={`downImg ${
                                    openRowIds.includes(item.id)
                                      ? "rotated"
                                      : ""
                                  }`}
                                  src={downArrow}
                                  alt="downImg"
                                />
                              </button>
                            </td>
                            <td style={{ width: "6%" }}>
                              <a href="">
                                <img
                                  src={
                                    item.babyModel
                                      ? `data:image/png;base64,${item.babyModel}`
                                      : babyModalReplacement
                                  }
                                  className="babyModel-image-list-img"
                                  alt="baby image"
                                ></img>
                              </a>
                            </td>
                            <td>
                              {item.omniBaseProjectId ? (
                                  <button className="exploreButton">
                                    <img src={cube} alt="explore in 3D" />
                                    <a
                                      href={
                                        item.project?.id === 159
                                          ? `https://bimpartners.iv.navvis.com/?site=3440531431214893&vlon=3.09&vlat=-0.21&fov=100.0&image=1533100006851755`
                                          : `${omnibaseUrl}/projects/${item.omniBaseProjectId}/view?org_uuid=${item.project.omniBaseOrgId}`
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {t("exploreIn3D")}
                                    </a>
                                  </button>
                                ) : (
                                  <button className="actionsBtn3" disabled>
                                  <img src={cube} alt="explore in 3D" />
                                    <a>{t("exploreIn3D")}</a>
                                  </button>
                                )} 
                            </td>
                            <td>{item.name}</td>
                            <td>{item.streetName}</td>
                            <td>{item.postcode}</td>
                            <td>{item.pandId}</td>
                            <td>{item.city}</td>
                            <td>{item.country}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
                {openRowIds.includes(item.id) && buildingData[item.id] && (
                  <BuildingDetailsTable
                    item={item}
                    buildingId={item.id}
                    addressReportList={buildingData[item.id].addressReportList}
                    rowHeadData={rowHeadData}
                    buildingReportList={buildingData[item.id].buildingReportList}
                    drivePath={buildingData.drivePath}
                  />
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      ) : (
        "Loading..."
      )}
    </div>
  );
};
